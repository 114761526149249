const actionTypes = {
    GET_APP_CONFIG_REQUEST: "GET_APP_CONFIG_REQUEST",
    GET_APP_CONFIG_FAIL: "GET_APP_CONFIG_FAIL",
    GET_APP_CONFIG_SUCCESS: "GET_APP_CONFIG_SUCCESS",
    //UNFOCUS_SEARCH: "UNFOCUS_SEARCH",
    //FOCUS_SEARCH: "FOCUS_SEARCH",
    //ACTIVE_VOUCHER: "ACTIVE_VOUCHER",
    //DEACTIVE_VOUCHER: "DEACTIVE_VOUCHER",
    CLEAR_UPSELL: "CLEAR_UPSELL",
    //MAP_REGION_CHANGED: "MAP_REGION_CHANGED",
    //USER_LOCATION_INIT: "USER_LOCATION_INIT",
    //USER_LOCATION_CHANGED: "USER_LOCATION_CHANGED",
    GET_STORES_REQUEST: "GET_STORES_REQUEST",
    GET_STORES_SUCCESS: "GET_STORES_SUCCESS",
    GET_STORES_FAILURE: "GET_STORES_FAILURE",
    GET_CMS_CONTENT_REQUEST: "GET_CMS_CONTENT_REQUEST",
    GET_CMS_CONTENT_SUCCESS: "GET_CMS_CONTENT_SUCCESS",
    GET_CMS_CONTENT_FAILURE: "GET_CMS_CONTENT_FAILURE",
    SET_PAYMENT_METHOD: "SET_PAYMENT_METHOD",
    PRODUCT_UNEDITABLE: "PRODUCT_UNEDITABLE",
    //TOGGLE_FACILITY_FILTER: "TOGGLE_FACILITY_FILTER",
    //CHANGE_SEARCH_TEXT: "CHANGE_SEARCH_TEXT",
    //GET_LOCATIONS_REQUEST: "GET_LOCATIONS_REQUEST",
    //GET_LOCATIONS_SUCCESS: "GET_LOCATIONS_SUCCESS",
    //GET_LOCATIONS_FAILURE: "GET_LOCATIONS_FAILURE",
    //MAP_REGION_AND_USER_LOCATION_CHANGED: "MAP_REGION_AND_USER_LOCATION_CHANGED",
    //TOGGLE_FAV_ONLY: "TOGGLE_FAV_ONLY",
    //TOGGLE_OFF_FAV_ONLY: "TOGGLE_OFF_FAV_ONLY",
    //UPDATE_DISPLAYED_STORES_MAP: "UPDATE_DISPLAYED_STORES_MAP",
    //UPDATE_DISPLAYED_STORES_LIST: "UPDATE_DISPLAYED_STORES_LIST",
    //UPDATE_DISTANCES_AND_SORT: "UPDATE_DISTANCES_AND_SORT",
    //UPDATE_DISTANCES: "UPDATE_DISTANCES",
    //UPDATE_SORT: "UPDATE_SORT",
    //DOWNLOAD_SHAKE_VOUCHERS: "DOWNLOAD_SHAKE_VOUCHERS",
    //GET_SHAKE_INFO_REQUEST: "GET_SHAKE_INFO_REQUEST",
    //GET_SHAKE_INFO_SUCCESS: "GET_SHAKE_INFO_SUCCESS",
    //GET_SHAKE_INFO_FAILURE: "GET_SHAKE_INFO_FAILURE",
    //GET_SHAKE_OFFER_REQUEST: "GET_SHAKE_OFFER_REQUEST",
    //GET_SHAKE_OFFER_SUCCESS: "GET_SHAKE_OFFER_SUCCESS",
    // GET_SHAKE_OFFER_FAILURE: "GET_SHAKE_OFFER_FAILURE",
    // ACCEPT_OFFER_REQUEST: "ACCEPT_OFFER_REQUEST",
    // ACCEPT_OFFER_SUCCESS: "ACCEPT_OFFER_SUCCESS",
    // ACCEPT_OFFER_FAILURE: "ACCEPT_OFFER_FAILURE",
    // FIND_NEAREST_STORE: "FIND_NEAREST_STORE",
    // INIT_STW_STATE: "INIT_STW_STATE",
    // CLEAR_VOUCHER: "CLEAR_VOUCHER",
    // CLEAR_PRIZE: "CLEAR_PRIZE",
    // UPDATE_SCREEN_SIZE: "UPDATE_SCREEN_SIZE",
    // SAVE_CURRENT_PRODUCT_DETAIL: "SAVE_CURRENT_PRODUCT_DETAIL",
    // RESTORE_CURRENT_PRODUCT_DETAIL: "RESTORE_CURRENT_PRODUCT_DETAIL",
    // UPDATE_SELECTED_STORE: 'UPDATE_SELECTED_STORE',
    // SHOW_LOADING_MENU: "SHOW_LOADING_MENU",
    // SET_SALES_GROUPS: "SET_SALES_GROUPS",
    // CHANGE_SELECTED_ITEM: "CHANGE_SELECTED_ITEM",
    // ADD_MODIFIER: "ADD_MODIFIER",
    // RM_MODIFIER: "RM_MODIFIER",
    // RESET_MODIFIER: "RESET_MODIFIER",
    // SET_ACTIONS: "SET_ACTIONS",
    // RESET_ACTIONS: "RESET_ACTIONS",
    // LOAD_ACTIONS: "LOAD_ACTIONS",
    // SWITCH_SALES_GROUPS: "SWITCH_SALES_GROUPS",
    APPLY_CART_VOUCHER: "APPLY_CART_VOUCHER",
    REMOVE_CART_VOUCHER: "REMOVE_CART_VOUCHER",
    ADD_ITEM_TO_CART: "ADD_ITEM_TO_CART",
    REMOVE_ITEM_FROM_CART: "REMOVE_ITEM_FROM_CART",
    EDIT_CART_ITEM: "EDIT_CART_ITEM",
    SET_SALES_ITEM: "SET_SALES_ITEM",
    SET_SALES_ITEM_MODIFIER: "SET_SALES_ITEM_MODIFIER",
    RESET_SALES_ITEM_MODIFICATIONS: "RESET_SALES_ITEM_MODIFICATIONS",
    RECALCULATE_CART_TOTALS: "RECALCULATE_CART_TOTALS",
    CHECK_CART_AVAILABILITY: "CHECK_CART_AVAILABILITY",
    // UPDATE_ITEM_IN_CART: "UPDATE_ITEM_IN_CART",
    // UPDATE_ITEM_QUANTITY: "UPDATE_ITEM_QUANTITY",
    // ADD_QUANTITY: "ADD_QUANTITY",
    // SUB_QUANTITY: "SUB_QUANTITY",
    // RESET_QUANTITY: "RESET_QUANTITY",
    // LOAD_CART_ITEM: "LOAD_CART_ITEM",
    // CHECK_CART_TIME_RESTRICTIONS: "CHECK_CART_TIME_RESTRICTIONS",
    // SET_CART_CARD_REFERENCE: "SET_CART_CARD_REFERENCE",
    REGISTER_REQUEST: "REGISTER_REQUEST",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_FAILURE: "REGISTER_FAILURE",
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    GUEST_LOGIN_REQUEST: "GUEST_LOGIN_REQUEST",
    GUEST_LOGIN_SUCCESS: "GUEST_LOGIN_SUCCESS",
    GUEST_LOGIN_FAILURE: "GUEST_LOGIN_FAILURE",
    GUEST_LOGIN_RESET: "GUEST_LOGIN_RESET",
    FB_LOGIN_REQUEST: "FB_LOGIN_REQUEST",
    FB_LOGIN_SUCCESS: "FB_LOGIN_SUCCESS",
    FB_LOGIN_FAILURE: "FB_LOGIN_FAILURE",
    FB_CONNECT_REQUEST: "FB_CONNECT_REQUEST",
    FB_CONNECT_FAIL: "FB_CONNECT_FAIL",
    FB_CONNECT_SUCCESS: "FB_CONNECT_SUCCESS",
    LOGOUT: "LOGOUT",
    EXPIRE_SESSION: "EXPIRE_SESSION",
    REFRESH_REQUEST: "REFRESH_REQUEST",
    REFRESH_SUCCESS: "REFRESH_SUCCESS",
    REFRESH_FAILURE: "REFRESH_FAILURE",
    GET_MENU_REQUEST: "GET_MENU_REQUEST",
    GET_MENU_SUCCESS: "GET_MENU_SUCCESS",
    GET_MENU_FAILURE: "GET_MENU_FAILURE",
    CREATE_ORDER_REQUEST: "CREATE_ORDER_REQUEST",
    CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
    CREATE_ORDER_FAILURE: "CREATE_ORDER_FAILURE",
    CONFIRM_ORDER_REQUEST: "CONFIRM_ORDER_REQUEST",
    CONFIRM_ORDER_SUCCESS: "CONFIRM_ORDER_SUCCESS",
    CONFIRM_ORDER_FAILURE: "CONFIRM_ORDER_FAILURE",
    // CHECK_AVAILABILITY: "CHECK_AVAILABILITY",
    // REORDER: "REORDER",
    GET_ALL_ORDERS_REQUEST: "GET_ALL_ORDERS_REQUEST",
    GET_ALL_ORDERS_SUCCESS: "GET_ALL_ORDERS_SUCCESS",
    GET_ALL_ORDERS_FAIL: "GET_ALL_ORDERS_FAIL",
    GET_ACTIVE_ORDERS_REQUEST: "GET_ACTIVE_ORDERS_REQUEST",
    GET_ACTIVE_ORDERS_SUCCESS: "GET_ACTIVE_ORDERS_SUCCESS",
    GET_ACTIVE_ORDERS_FAIL: "GET_ACTIVE_ORDERS_FAIL",
    GET_PAST_ORDERS_REQUEST: "GET_PAST_ORDERS_REQUEST",
    GET_PAST_ORDERS_SUCCESS: "GET_PAST_ORDERS_SUCCESS",
    GET_PAST_ORDERS_FAIL: "GET_PAST_ORDERS_FAIL",
    GET_UPCOMING_ORDERS_REQUEST: "GET_UPCOMING_ORDERS_REQUEST",
    GET_UPCOMING_ORDERS_SUCCESS: "GET_UPCOMING_ORDERS_SUCCESS",
    GET_UPCOMING_ORDERS_FAIL: "GET_UPCOMING_ORDERS_FAIL",
    // CLEAR_CART: "CLEAR_CART",
    GET_VOUCHERS_REQUEST: "GET_VOUCHERS_REQUEST",
    GET_VOUCHERS_SUCCESS: "GET_VOUCHERS_SUCCESS",
    GET_VOUCHERS_FAILURE: "GET_VOUCHERS_FAILURE",
    // GET_NEWS_FEED_REQUEST: "GET_NEWS_FEED_REQUEST",
    // GET_NEWS_FEED_SUCCESS: "GET_NEWS_FEED_SUCCESS",
    // GET_NEWS_FEED_FAILURE: "GET_NEWS_FEED_FAILURE",
    UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
    UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
    // GET_UPSELL_REQUEST: "GET_UPSELL_REQUEST",
    GET_UPSELL_SUCCESS: "GET_UPSELL_SUCCESS",
    // GET_UPSELL_FAILURE: "GET_UPSELL_FAILURE",
    RATE_ORDER_REQUEST: "RATE_ORDER_REQUEST",
    RATE_ORDER_SUCCESS: "RATE_ORDER_SUCCESS",
    RATE_ORDER_FAILURE: "RATE_ORDER_FAILURE",
    // POPULATE_SALES_ITEMS: "POPULATE_SALES_ITEMS",
    // LOAD_CART_ITEM_ACTION: "LOAD_CART_ITEM_ACTION",
    // UPDATE_PRICE: "UPDATE_PRICE",
    //SET_MODIFIER_QUANTITY: "SET_MODIFIER_QUANTITY",
    VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
    VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
    VERIFY_EMAIL_FAIL: "VERIFY_EMAIL_FAIL",
    REQUEST_RESET_PASSWORD_REQUEST: "REQUEST_RESET_PASSWORD_REQUEST",
    REQUEST_RESET_PASSWORD_SUCCESS: "REQUEST_RESET_PASSWORD_SUCCESS",
    REQUEST_RESET_PASSWORD_FAIL: "REQUEST_RESET_PASSWORD_FAIL",
    RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",
    //SET_FACILITY_FILTER: "SET_FACILITY_FILTER",
    // PAY_ORDER_REQUEST: "PAY_ORDER_REQUEST",
    // PAY_ORDER_SUCCESS: "PAY_ORDER_SUCCESS",
    // PAY_ORDER_FAILURE: "PAY_ORDER_FAILURE",
    GET_CARDS_REQUEST: "GET_CARDS_REQUEST",
    GET_CARDS_SUCCESS: "GET_CARDS_SUCCESS",
    GET_CARDS_FAILURE: "GET_CARDS_FAILURE",
    SET_SELECTED_CARD: "SET_SELECTED_CARD",
    // ADD_CARD_REQUEST: "ADD_CARD_REQUEST",
    // ADD_CARD_SUCCESS: "ADD_CARD_SUCCESS",
    // ADD_CARD_VERIFY: "ADD_CARD_VERIFY",
    // ADD_CARD_FAILURE: "ADD_CARD_FAILURE",
    DISABLE_CARD_REQUEST: "DISABLE_CARD_REQUEST",
    DISABLE_CARD_SUCCESS: "DISABLE_CARD_SUCCESS",
    DISABLE_CARD_FAIL: "DISABLE_CARD_FAIL",
    // CHECK_STORE_REQUEST: "CHECK_STORE_REQUEST",
    // CHECK_STORE_SUCCESS: "CHECK_STORE_SUCCESS",
    // CHECK_STORE_FAILURE: "CHECK_STORE_FAILURE",
    CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",
    RESEND_VERIFICATION_REQUEST: "RESEND_VERIFICATION_REQUEST",
    RESEND_VERIFICATION_SUCCESS: "RESEND_VERIFICATION_SUCCESS",
    RESEND_VERIFICATION_FAIL: "RESEND_VERIFICATION_FAIL",
    GET_INFO_REQUEST: "GET_INFO_REQUEST",
    GET_INFO_SUCCESS: "GET_INFO_SUCCESS",
    GET_INFO_FINISH: "GET_INFO_FINISH",
    GET_INFO_FAIL: "GET_INFO_FAIL",
    SET_TOOLTIP_IDS: "SET_TOOLTIP_IDS",
    SHOW_LOADING: "SHOW_LOADING",
    HIDE_LOADING: "HIDE_LOADING",
    // CLEAR_SALES_GROUP: "CLEAR_SALES_GROUP",
    // CHECK_MODIFIER_AMOUNT: "CHECK_MODIFIER_AMOUNT",
    // new here
    INITIAL_FETCH_ALL_REQUEST: "INITIAL_FETCH_ALL_REQUEST",
    INITIAL_FETCH_ALL_SUCCESS: "INITIAL_FETCH_ALL_SUCCESS",
    INITIAL_FETCH_ALL_FAILURE: "INITIAL_FETCH_ALL_FAILURE",
    AUTHENTICATED: "AUTHENTICATED",
    SET_CMS_NAV: "SET_CMS_NAV",
    SET_CMS_FOOTER: "SET_CMS_FOOTER",
    SET_GEOLOCATION_STATUS: "SET_GEOLOCATION_STATUS",
    SET_SELECTED_STORE: "SET_SELECTED_STORE",
    SET_ORDER_METHOD: "SET_ORDER_METHOD",
    RESET_ORDERING: "RESET_ORDERING",
    SET_DAYPART_MENU: "SET_DAYPART_MENU",
    SET_PRODUCT_DETAIL: "SET_PRODUCT_DETAIL",
    OPEN_GLOBAL_MODAL: "OPEN_GLOBAL_MODAL",
    CLOSE_GLOBAL_MODAL: "CLOSE_GLOBAL_MODAL",
    OPEN_ALERT_MODAL: "OPEN_ALERT_MODAL",
    CLOSE_ALERT_MODAL: "CLOSE_ALERT_MODAL",
    CLOSE_ALL_MODALS: "CLOSE_ALL_MODALS",
    OPEN_VOUCHER_MODAL: "OPEN_VOUCHER_MODAL",
    CLOSE_VOUCHER_MODAL: "CLOSE_VOUCHER_MODAL",
    OPEN_BARCODE_OVERLAY: "OPEN_BARCODE_OVERLAY",
    CLOSE_BARCODE_OVERLAY: "CLOSE_BARCODE_OVERLAY",
    TOGGLE_PERSISTENT_CART: "TOGGLE_PERSISTENT_CART",
    SET_MENU_SPECIAL_CATEGORIES: "SET_MENU_SPECIAL_CATEGORIES",
    SET_DELIVERY_LOCATION: "SET_DELIVERY_LOCATION",
    SET_DELIVERY_TIME: "SET_DELIVERY_TIME",
    GET_SAVED_ADDRESSES_REQUEST: "GET_SAVED_ADDRESSES_REQUEST",
    GET_SAVED_ADDRESSES_SUCCESS: "GET_SAVED_ADDRESSES_SUCCESS",
    GET_SAVED_ADDRESSES_FAIL: "GET_SAVED_ADDRESSES_FAIL",
    ADD_SAVED_ADDRESS_REQUEST: "ADD_SAVED_ADDRESS_REQUEST",
    ADD_SAVED_ADDRESS_SUCCESS: "ADD_SAVED_ADDRESS_SUCCESS",
    ADD_SAVED_ADDRESS_FAIL: "ADD_SAVED_ADDRESS_FAIL",
    UPDATE_SAVED_ADDRESS_REQUEST: "UPDATE_SAVED_ADDRESS_REQUEST",
    UPDATE_SAVED_ADDRESS_SUCCESS: "UPDATE_SAVED_ADDRESS_SUCCESS",
    UPDATE_SAVED_ADDRESS_FAIL: "UPDATE_SAVED_ADDRESS_FAIL",
    DELETE_SAVED_ADDRESS_REQUEST: "DELETE_SAVED_ADDRESS_REQUEST",
    DELETE_SAVED_ADDRESS_SUCCESS: "DELETE_SAVED_ADDRESS_SUCCESS",
    DELETE_SAVED_ADDRESS_FAIL: "DELETE_SAVED_ADDRESS_FAIL",
    // product/meal related
    // SET_POS_PRODUCT: "SET_POS_PRODUCT",
    // SET_PROMO_PRODUCT: "SET_PROMO_PRODUCT",
    SET_PRODUCT: "SET_PRODUCT",
    SET_COMBO_LEVEL: "SET_COMBO_LEVEL",
    SET_QUANTITY: "SET_QUANTITY",
    RESET_PRODUCT_CHANGES: "RESET_PRODUCT_CHANGES",
    CALCULATE_PRODUCT_TOTALS: "CALCULATE_PRODUCT_TOTALS",
    SAVE_DEEPLINK: "SAVE_DEEPLINK",
    CLEAR_DEEPLINK: "CLEAR_DEEPLINK",
    //EDIT_SALES_ITEM: "EDIT_SALES_ITEM",
    TOGGLE_NAV: "TOGGLE_NAV",
    ADD_FIXED_FOOTER_ITEM: "ADD_FIXED_FOOTER_ITEM",
    REMOVE_FIXED_FOOTER_ITEM: "REMOVE_FIXED_FOOTER_ITEM",
    CALC_FIXED_FOOTER_HEIGHT: "CALC_FIXED_FOOTER_HEIGHT",
    ADD_PAST_ORDER_TO_CART: "ADD_PAST_ORDER_TO_CART",
    TOGGLE_ACTIVE_ORDER: "TOGGLE_ACTIVE_ORDER",
    SET_LOGIN_REDIRECT: "SET_LOGIN_REDIRECT",
    CLEAR_LOGIN_REDIRECT: "CLEAR_LOGIN_REDIRECT",
    STORE_GUEST_FORM: "STORE_GUEST_FORM",
    EDIT_SAVED_ADDRESS_REQUEST: "EDIT_SAVED_ADDRESS_REQUEST",
    EDIT_SAVED_ADDRESS_SUCCESS: "EDIT_SAVED_ADDRESS_SUCCESS",
    EDIT_SAVED_ADDRESS_FAIL: "EDIT_SAVED_ADDRESS_FAIL",
    GET_COMP_DETAIL_REQUEST: "GET_COMP_DETAIL_REQUEST",
    GET_COMP_DETAIL_SUCCESS: "GET_COMP_DETAIL_SUCCESS",
    GET_COMP_DETAIL_FAILURE: "GET_COMP_DETAIL_FAILURE",
    GET_COMP_DETAIL_COMPLETE: "GET_COMP_DETAIL_COMPLETE",
    REMOVE_COMP_CODE: "REMOVE_COMP_CODE",
    SET_PROMO_ERROR: "SET_COMP_CODE_ERROR",
    CLEAR_PROMO_ERROR: "CLEAR_COMP_CODE_ERROR",
    SET_APPLEPAY_SUPPORT: "SET_APPLEPAY_SUPPORT",
    SET_GOOGLEPAY_SUPPORT: "SET_GOOGLEPAY_SUPPORT",
    CHECK_DISCOUNTED_ITEM: "CHECK_DISCOUNTED_ITEM",
    GET_GUEST_COFFEE_LOYALTY_REQUEST: "GET_GUEST_COFFEE_LOYALTY_REQUEST",
    GET_GUEST_COFFEE_LOYALTY_SUCCESS: "GET_GUEST_COFFEE_LOYALTY_SUCCESS",
    GET_GUEST_COFFEE_LOYALTY_FAILURE: "GET_GUEST_COFFEE_LOYALTY_FAILURE",
    GET_COFFEE_LOYALTY_REQUEST: "GET_COFFEE_LOYALTY_REQUEST",
    GET_COFFEE_LOYALTY_SUCCESS: "GET_COFFEE_LOYALTY_SUCCESS",
    GET_COFFEE_LOYALTY_FAILURE: "GET_COFFEE_LOYALTY_FAILURE",
    GET_COFFEE_LOYALTY_ELIGABLE_REQUEST: "GET_COFFEE_LOYALTY_ELIGABLE_REQUEST",
    GET_COFFEE_LOYALTY_ELIGABLE_SUCCESS: "GET_COFFEE_LOYALTY_ELIGABLE_SUCCESS",
    GET_COFFEE_LOYALTY_ELIGABLE_FAILURE: "GET_COFFEE_LOYALTY_ELIGABLE_FAILURE",
    SET_LAUNCH_DARKLY_FLAGS: "SET_LAUNCH_DARKLY_FLAGS"
};

export default actionTypes;
