// @flow

import Config from "@Config";
import ActionTypes from "@actionTypes";
import ApiFactory from "@apis/APIFactory";

//
// Adyen load sdk promise
//
export const initAdyenSDK = (): any => (dispatch: any): any => {
    return new Promise((resolve: any, reject: any) => {
        // timeout reject
        let requestTimeout = setTimeout(() => {
            reject("Adyen JS SDK timed out.");
        }, Config.fetchTimeout);

        // test interval
        let requestInterval = setInterval(() => {
            if (window.AdyenCheckout) {
                // adyen checkout exists
                clearTimeout(requestTimeout);
                clearInterval(requestInterval);
                resolve();
            }
        }, 50);

        // async script include
        let adyenEnv =
            window.hj_env && window.hj_env === "prod" ? "live" : "test";
        let adyenSdkJS: any = document.createElement("script");
        adyenSdkJS.type = "text/javascript";
        adyenSdkJS.async = true;
        adyenSdkJS.src = `https://checkoutshopper-${adyenEnv}${
            window.hj_env === "prod" ? "-au" : ""
        }.adyen.com/checkoutshopper/sdk/${
            Config.adyen.adyenSDKVersion
        }/adyen.js`;
        adyenSdkJS.integrity =
            window.hj_env === "prod"
                ? "sha384-avpSB6KxKproYEUN8fw5KPlxIL4XDcAR0Av/cyql9/v0ttzkddTK2XmILq5L1/4/"
                : "";
        adyenSdkJS.crossOrigin = "anonymous";
        if (document && document.body) document.body.appendChild(adyenSdkJS);
        let adyenSdkCSS: any = document.createElement("link");
        adyenSdkCSS.rel = "stylesheet";
        adyenSdkCSS.async = true;
        adyenSdkCSS.href = `https://checkoutshopper-${adyenEnv}.adyen.com/checkoutshopper/sdk/${Config.adyen.adyenSDKVersion}/adyen.css`;
        adyenSdkCSS.integrity =
            "sha384-y1lKqffK5z+ENzddmGIfP3bcMRobxkjDt/9lyPAvV9H3JXbJYxCSD6L8TdyRMCGM";
        adyenSdkCSS.crossOrigin = "anonymous";
        if (document && document.body) document.body.appendChild(adyenSdkCSS);
        let googlePaySdk: any = document.createElement("script");
        googlePaySdk.type = "text/javascript";
        googlePaySdk.async = true;
        googlePaySdk.src = `https://pay.google.com/gp/p/js/pay.js`;
        if (document && document.body) document.body.appendChild(googlePaySdk);
    });
};

///
/// Get Cards
///
export const getCardsRequest = (): any => ({
    type: ActionTypes.GET_CARDS_REQUEST
});

export const getCardsSuccess = (result: any): any => ({
    type: ActionTypes.GET_CARDS_SUCCESS,
    result
});

export const getCardsFailure = (): any => ({
    type: ActionTypes.GET_CARDS_FAILURE
});

export const getCards = (): any => (dispatch: any, getState: any): any => {
    const {
        user: { key }
    } = getState();
    if (key) {
        dispatch(getCardsRequest());
        return ApiFactory.CardAPI.getCards(key)
            .then((response: any): any => {
                dispatch(getCardsSuccess(response));
            })
            .catch(() => {
                dispatch(getCardsFailure());
            });
    }
    return dispatch(getCardsFailure());
};

///
/// Set Selected Card
///

export const setSelectedCard = (recurringDetailReference: any): any => ({
    type: ActionTypes.SET_SELECTED_CARD,
    recurringDetailReference
});

///
/// Delete/Disable Card
///

const disableCardRequest = (): any => ({
    type: ActionTypes.DISABLE_CARD_REQUEST
});

const disableCardSuccess = (result: any): any => ({
    type: ActionTypes.DISABLE_CARD_SUCCESS,
    result
});

const disableCardFail = (error: any): any => ({
    type: ActionTypes.DISABLE_CARD_FAIL,
    error
});

export const disableCard = (reference: any): any => (
    dispatch: any,
    getState: any
): any => {
    dispatch(disableCardRequest());
    const { user } = getState();
    return ApiFactory.CardAPI.disableCard(user.key, reference)
        .then((res: any): any => {
            dispatch(disableCardSuccess(res));
            return dispatch(getCards());
        })
        .catch((error: any): any => {
            dispatch(disableCardFail(error));
            throw error;
        });
};
